import Config from './Config';
import UserAgentDetector from '../utils/UserAgentDetector';

export default new class ScaleManager {
  constructor() {
    window.addEventListener('visibilitychange', this.requestWakeLock.bind(this));
    this.requestWakeLock();

    this._isResizeListenersAttached = false;
    this.initialized = false;
  }

  preInit(data, config) { //Init without canvas
    this.init(data, config, true);
  }

  init(data, config, isPreInit) {
    if (this.initialized) return;
    this.initialized = !isPreInit;
    this.config = config ? { ...Config, ...config } : Config;
    this.currentSizes = null;
    this._currentData = null;
    this._bottomOffset = 0;
    this.gameContainer = window.OPWrapperService.initConfig.gameContainer;

    this.data = data || null;
    this.addListeners();
    this.onResize(true);
  }

  addListeners() {
    if (this._isResizeListenersAttached) return;
    this._isResizeListenersAttached = true;

    const listener = this.onResize.bind(this);

    visualViewport.addEventListener('resize', listener);

    document.addEventListener('fullscreenchange', listener);
    /* Firefox */
    document.addEventListener("mozfullscreenchange", listener);
    /* Chrome, Safari and Opera */
    document.addEventListener("webkitfullscreenchange", listener);
    /* IE / Edge */
    document.addEventListener("msfullscreenchange", listener);
  }

  makeScalingHTMLElement({
                           HTMLElement,
                           relativeScale, //0 - 1
                           // not required
                           defaultWidth, //px
                           defaultHeight, //px
                           forceLeftMargin, //px
                           forceTopMargin, //px
                         }) {
    let leftMargin,
      leftCorrectionForScale,
      topMargin,
      topCorrectionForScale

    if (defaultWidth) {
      const innerWidth = this.currentData ? this.currentData.innerWidth : visualViewport.width;
      leftMargin = typeof forceLeftMargin === 'number' ? forceLeftMargin : (innerWidth - defaultWidth * relativeScale) / 2
      leftCorrectionForScale = (defaultWidth - defaultWidth * relativeScale) / 2
    }
    if (defaultHeight) {
      const innerHeight = this.currentData ? this.currentData.innerHeight : visualViewport.height;
      topMargin = typeof forceTopMargin === 'number' ? forceTopMargin : this.config && this.config.BIND_TO_TOP ? 0 : (innerHeight - defaultHeight * relativeScale) / 2
      topCorrectionForScale = (defaultHeight - defaultHeight * relativeScale) / 2
    }

    Object.assign(HTMLElement.style, {
      transform: `scale(${relativeScale}) translateZ(0)`,
      width: defaultWidth ? `${defaultWidth}px` : undefined,
      height: defaultHeight ? `${defaultHeight}px` : undefined,
      left: defaultWidth ? `${leftMargin - leftCorrectionForScale}px` : undefined,
      top: defaultHeight ? `${topMargin - topCorrectionForScale}px` : undefined,
    });
  }

  onResize(isForce) {
    let newInnerWidth = visualViewport.width;
    let newInnerHeight = visualViewport.height;

    if (this.innerWidth === newInnerWidth
      && this.innerHeight === newInnerHeight
      && this._bottomOffset === this._savedBottomOffset
      && isForce !== true
    ) return;

    this._savedBottomOffset = this._bottomOffset
    this.innerWidth = newInnerWidth;
    this.innerHeight = newInnerHeight;

    this.checkOrientation();
    this.calculateCurrentSize();

    const scaleRatio = (this.data && this.data.scaleRatio) || 1; //UserAgentDetector.isMobile.any ? 2 : 1;

    const finalInnerWidth = this.innerWidth * scaleRatio;
    const finalInnerHeight = this.innerHeight * scaleRatio;

    const scale = Math.min(finalInnerWidth / this.safeZone.width, (finalInnerHeight - this._bottomOffset) / this.safeZone.height);

    this._currentData = {
      orientation: this.orientation,
      isPortrait: this.orientation === 'V',
      isLandscape: this.orientation === 'H',

      gameWidth: this.currentSizes.width,
      gameHeight: this.currentSizes.height,
      stageX: (finalInnerWidth - (this.safeZone.width * scale)) / 2 - (this.safeZone.left) * scale,
      stageY: this.config.BIND_TO_TOP ? 0 : (finalInnerHeight - (this.safeZone.height * scale)) / 2 - this.safeZone.top * scale - this._bottomOffset / 2,

      bottomOffset: this._bottomOffset,
      isMobile: UserAgentDetector.isMobile.any,
      scaleWidth: finalInnerWidth / scale / this.currentSizes.width,
      scaleHeight: finalInnerHeight / scale / this.currentSizes.height,
      innerWidth: this.innerWidth,
      innerHeight: this.innerHeight,
      finalInnerWidth: finalInnerWidth,
      finalInnerHeight: finalInnerHeight,
      scale
    };

    if (this.initialized) this.updateCanvas();
    this.emitResize();
  }

  updateCanvas() {
    this.data.app.view.style.width = `${this.currentData.innerWidth}px`;
    this.data.app.view.style.height = `${this.currentData.innerHeight}px`;
    this.data.app.renderer.resize(this.currentData.finalInnerWidth, this.currentData.finalInnerHeight);
    this.data.app.stage.scale.set(this.currentData.scale, this.currentData.scale);
    this.data.app.stage.x = this.currentData.stageX;
    this.data.app.stage.y = this.currentData.stageY

    this._currentData = {
      ...this._currentData,
      canvasWidth: this.data.app.view.width,
      canvasHeight: this.data.app.view.height,
      stageHeight: this.data.app.stage.height,
      stageWidth: this.data.app.stage.width,
    }
  }

  emitResize() {
    window.OPWrapperService.eventManager.dispatch(window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE, this.currentData);
  }

  checkOrientation() {
    if (this.config.FORCE_PORTRAIT || this.innerWidth < this.innerHeight && UserAgentDetector.isMobile.any) {
      this.orientation = 'V';
      this.shiftX = (this.config.CANVAS_WIDTH_PORTRAIT - this.config.SAFE_WIDTH_PORTRAIT) / 2;
      this.shiftY = this.config.BIND_TO_TOP ? 0 : (this.config.CANVAS_HEIGHT_PORTRAIT - this.config.SAFE_HEIGHT_PORTRAIT) / 2;
      this.safeZone = { left: this.shiftX, top: this.shiftY, width: this.config.SAFE_WIDTH_PORTRAIT, height: this.config.SAFE_HEIGHT_PORTRAIT };
      this.gameRatio = this.config.CANVAS_WIDTH_PORTRAIT / this.config.CANVAS_HEIGHT_PORTRAIT;
      window.OPWrapperService.ControllerStatistic.setOrientation('portrait');
    } else {
      this.orientation = 'H';
      this.shiftX = (this.config.CANVAS_WIDTH_LANDSCAPE - this.config.SAFE_WIDTH_LANDSCAPE) / 2;
      this.shiftY = this.config.BIND_TO_TOP ? 0 : (this.config.CANVAS_HEIGHT_LANDSCAPE - this.config.SAFE_HEIGHT_LANDSCAPE) / 2;
      this.safeZone = { left: this.shiftX, top: this.shiftY, width: this.config.SAFE_WIDTH_LANDSCAPE, height: this.config.SAFE_HEIGHT_LANDSCAPE };
      this.gameRatio = this.config.CANVAS_WIDTH_LANDSCAPE / this.config.CANVAS_HEIGHT_LANDSCAPE;
      window.OPWrapperService.ControllerStatistic.setOrientation('landscape');
    }
  }

  calculateCurrentSize() {
    let windowRatio = this.innerWidth / this.innerHeight;
    this.currentSizes = UserAgentDetector.isMobile.any ? (windowRatio >= 1 ? this.data.gameSize.mobileLandscape : this.data.gameSize.mobilePortrait) : this.data.gameSize.desktop;
    if (!this.currentSizes) {
      this.currentSizes = this.data.gameSize;
    }
  }

  async requestWakeLock() {
    if (document.visibilityState === 'visible' && "wakeLock" in navigator) {
      try {
        await navigator.wakeLock.request("screen");
      } catch (e) {

      }
    }
  }

  setBottomOffset(offset) {
    if (this._bottomOffset === offset) return;
    this._bottomOffset = offset || 0;
    this.onResize();
  }

  get currentData() {
    return this._currentData;
  }
}
