import Event from './utils/event';

export default new class GameScaleManager {
  constructor() {
    this.defaultCanvasSize = { H: { width: 1214, height: 594 }, V: { width: 898, height: 718 } };
    this.defaultScreenSize = { H: { width: 1920, height: 1080 }, V: { width: 918, height: 1600 } };
    this.defaultScales = { H: 1.2, V: 1 };
    this.defaultStageShifts = { H: { x: 0, y: -150 }, V: { x: -58, y: 0 } };
    this._defaultFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    this._bottomOffset = 0;
    this.onResize = new Event();
    this.onChangeOrientation = new Event();

    this.currentScale = this.defaultScales.V;
    this.currentStageShift = this.defaultStageShifts.V;
    this._onResize = this._onResize.bind(this)
  }

  init(app) {
    window.OPWrapperService.ScaleManager = this;
    this.app = app;
    const listener = this._onResize;

    visualViewport.addEventListener('resize', listener);
    document.addEventListener('fullscreenchange', listener);
    /* Firefox */
    document.addEventListener('mozfullscreenchange', listener);
    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', listener);
    /* IE / Edge */
    document.addEventListener('msfullscreenchange', listener);

    listener();
  }

  set resizeBlocked(value) {
    if (value) {
      visualViewport.removeEventListener('resize', this._onResize);
      window.addEventListener('resize', this._onResize);
    } else {
      visualViewport.addEventListener('resize', this._onResize);
      window.removeEventListener('resize', this._onResize);
    }
    this._resizeBlocked = value;
  }

  get resizeBlocked() {
    return this._resizeBlocked;
  }


  _onResize() {
    let newInnerWidth = this._resizeBlocked ? document.documentElement.clientWidth : visualViewport.width;
    let newInnerHeight = this._resizeBlocked ? document.documentElement.clientHeight : visualViewport.height;

    const orientation = newInnerWidth > newInnerHeight ? 'H' : 'V';

    if (this.innerWidth === newInnerWidth
      && this.innerHeight === newInnerHeight
      && this._bottomOffset === this._savedBottomOffset
      || (this._resizeBlocked && orientation === this.orientation)) return;

    this.innerWidth = newInnerWidth;
    this.innerHeight = newInnerHeight;
    this._savedBottomOffset = this._bottomOffset


    document.documentElement.style.fontSize = Math.min(this.innerWidth, this.defaultScreenSize[orientation].width / this.defaultScreenSize[orientation].height * (this.innerHeight - this._bottomOffset)) * this._defaultFontSize / this.defaultScreenSize[orientation].width + 'px';
    const width = this.defaultCanvasSize[orientation].width;
    const height = this.defaultCanvasSize[orientation].height;
    this.app.view.style.width = `${width}px`;
    this.app.view.style.height = `${height}px`;
    this.app.renderer.resize(width, height);
    const scale = this.defaultScales[orientation];
    this.app.stage.scale.set(scale, scale);
    this.app.stage.x = this.defaultStageShifts[orientation].x;
    this.app.stage.y = this.defaultStageShifts[orientation].y;

    this.currentScale = scale;
    this.currentStageShift = this.defaultStageShifts[orientation];

    const data = {
      orientation,
      innerWidth: this.innerWidth,
      innerHeight: this.innerHeight,
      gameWidth: width,
      gameHeight: height,
      safeScreenWidth: this.defaultScreenSize[orientation].width,
      safeScreenHeight: this.defaultScreenSize[orientation].height,
      bottomOffset: this._bottomOffset,
    };

    this.safeZone = {
      width: data.safeScreenWidth,
      height: data.safeScreenHeight,
    }

    this.onResize.call(data);
    window.OPWrapperService.eventManager.dispatch(window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE, data);
    if (orientation !== this.orientation) {
      console.log(`Orientation:${orientation}`);
      this.orientation = orientation;
      this.onChangeOrientation.call(data);
    }
  }

  setBottomOffset(offset) {
    if (this._bottomOffset === offset) return;
    this._bottomOffset = offset || 0;
    this._onResize();
  }
};
