function preloadFontFace(name, src, weight) {
  return new Promise(async (resolve, reject) => {
    try {
      src = Array.isArray(src) ? src : [src];
      const sourcesStr = src.reduce((str, url, i) => {
        str += `url(${url})`;
        if (i !== src.length - 1) str += ', ';
        return str;
      }, '');

      const fontFace = new FontFace(name, sourcesStr, { weight });
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Font loading timed out')), 5000)
      );
      await Promise.race([fontFace.load(), timeoutPromise])
        .then((loaded_face) => {
          document.fonts.add(loaded_face);
          resolve(loaded_face);
          }
        );
    } catch (e) {
      const errorMessage = `Can't load '${name}' font (weight: ${weight})`;
      if (/#Blocked loading font/.test(e.message)) { // if manual blocked
        console.warn(errorMessage, '\n', e);
        resolve();
      } else {
        console.error(errorMessage, '\n', e);
        console.error(e);
        resolve(e);
      }
    }
  });
}

export {
  preloadFontFace,
};
